/* eslint-disable */
import Cookies from 'js-cookie';
import Vue from 'vue'
const RESULT_FIELD_NAME = "fieldname";
const RESULT_FIELD_VALUE = "fieldValue";
const RESULT_FIELD_TITLE = "fieldTitle";
const RESULT_FIELD_ORDER = "fieldOrder";
const RESULT_FIELD_TYPE = "fieldType";
const RESULT_FIELD_ATTRS = "fieldAttrs";
const commonFunc = {
  _stringTemplate(template, variables) {
    return template.replace(new RegExp('{([^{]+)}', 'g'), function (
      _unused,
      varName
    ) {
      // eslint-disable-line
      return variables[varName] || `{${varName}}`
    })
  },
  getRandomArrayElements: function (array, limit) {
    if (array.length <= limit) {
      const returnArray = JSON.parse(JSON.stringify(array))
      return returnArray
    } else {
      const tempArray = JSON.parse(JSON.stringify(array))
      const returnArray = []
      for (let i = 0; i < limit; i++) {
        const randomIndex = Math.floor(Math.random() * tempArray.length)
        returnArray.push(tempArray[randomIndex])
        tempArray.splice(randomIndex, 1)
      }
      return returnArray
    }
  },
  groupArray: function (array, subGroupLength) {
    let index = 0
    const newArray = []
    while (index < array.length) {
      newArray.push(array.slice(index, index += subGroupLength))
    }
    return newArray
  },
  getSeoMeta: function (seoData, logo, title = false) {
    const meta = [
      { hid: "og:title", name: "og:title", content: title ? title : seoData.title },
      {
        hid: "keywords",
        name: "keywords",
        content: seoData.keyword,
      },
      {
        hid: "og:description",
        name: "og:description",
        content: seoData.desc,
      },
      {
        hid: "description",
        name: "description",
        content: seoData.desc,
      },
    ]
    if (logo) {
      meta.push(
        { hid: "og:image", name: "og:image", content: logo }
      )
    }
    return {
      title: seoData.title,
      meta: meta
    };
  },
  isHKID: function (str) {
    var strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    // basic check length
    if (str.length < 8)
      return false;

    // handling bracket
    if (str.charAt(str.length - 3) == '(' && str.charAt(str.length - 1) == ')')
      str = str.substring(0, str.length - 3) + str.charAt(str.length - 2);

    // convert to upper case
    str = str.toUpperCase();

    // regular expression to check pattern and split
    var hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
    var matchArray = str.match(hkidPat);

    // not match, return false
    if (matchArray == null)
      return false;

    // the character part, numeric part and check digit part
    var charPart = matchArray[1];
    var numPart = matchArray[2];
    var checkDigit = matchArray[3];

    // calculate the checksum for character part
    var checkSum = 0;
    if (charPart.length == 2) {
      checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
      checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
    } else {
      checkSum += 9 * 36;
      checkSum += 8 * (10 + strValidChars.indexOf(charPart));
    }

    // calculate the checksum for numeric part
    for (var i = 0, j = 7; i < numPart.length; i++, j--)
      checkSum += j * numPart.charAt(i);

    // verify the check digit
    var remaining = checkSum % 11;
    var verify = remaining == 0 ? 0 : 11 - remaining;

    return verify == checkDigit || (verify == 10 && checkDigit == 'A');
  },
  getMixpanelProperty(jobObject) {
    const mpFunctions = jobObject.field ? jobObject.field.map((field) => { return field.en }) : []
    const mpSubFunctions = jobObject.field ? jobObject.field.map((field) => { if (field.children) return Object.keys(field.children).map(function (child, idx) { return field.children[child].en }) }).flat() : false
    const mpJobObject = {
      'job id': jobObject.id,
      'job level': (jobObject.manageriallevel && jobObject.manageriallevel.en) || '',
      industry: jobObject.industrydesc ? jobObject.industrydesc.en : '',
      industryId: jobObject.industry ? jobObject.industry : '',
      cluster: jobObject.clusterdesc ? jobObject.clusterdesc.en : '',
      clusterId: jobObject.cluster ? jobObject.cluster : '',
      function: mpFunctions[0] || '',
      functions: mpFunctions || '',
      'sub function': mpSubFunctions[0] || '',
      'sub functions': mpSubFunctions || '',
      'post type': jobObject.posttype,
      template: jobObject.customtemplate.use_template ? 'customized' : 'regular'
    }
    return mpJobObject
  },
  solrDecode: function (str) {
    if (str === null) {
      return ''
    }
    str = str.replace('&#039;', '')
    str = str.replace('&amp;;', '&')
    str = str.replace('\&quot;;', '"')
    str = str.replace('%23', '#')
    str = str.replace('%3d;', '=')
    str = str.replace('_n_', '&')
    str = str.replace('_p_', '+')
    str = str.replace('%25;', '%')
    str = str.replace('%2B', '+')
    str = str.replace('%27', '\'')
    return str
  },
  isHKID: function (str) {
    var strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    // basic check length
    if (str.length < 8)
      return false;

    // handling bracket
    if (str.charAt(str.length - 3) == '(' && str.charAt(str.length - 1) == ')')
      str = str.substring(0, str.length - 3) + str.charAt(str.length - 2);

    // convert to upper case
    str = str.toUpperCase();
    str = str.replace('-', '');
    // regular expression to check pattern and split
    var hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
    var matchArray = str.match(hkidPat);

    // not match, return false
    if (matchArray == null)
      return false;

    // the character part, numeric part and check digit part
    var charPart = matchArray[1];
    var numPart = matchArray[2];
    var checkDigit = matchArray[3];

    // calculate the checksum for character part
    var checkSum = 0;
    if (charPart.length == 2) {
      checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
      checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
    } else {
      checkSum += 9 * 36;
      checkSum += 8 * (10 + strValidChars.indexOf(charPart));
    }

    // calculate the checksum for numeric part
    for (var i = 0, j = 7; i < numPart.length; i++, j--)
      checkSum += j * numPart.charAt(i);

    // verify the check digit
    var remaining = checkSum % 11;
    var verify = remaining == 0 ? 0 : 11 - remaining;

    return verify == checkDigit || (verify == 10 && checkDigit == 'A');
  },
  matchPath: function (path, matchStr) {
    return matchStr.test(path)
  },
  isObject: function (mixedVar) {
    if (Array.isArray(mixedVar)) {
      return false
    } else {
      return (mixedVar !== null) && (typeof (mixedVar) === 'object')
    }
  },
  objectToArray: function objectToArray(obj) {
    const array = []
    let tempObject
    for (const key in obj) {
      tempObject = obj[key]
      if (this.isObject(obj[key])) {
        tempObject = this.objectToArray(obj[key])
      }
      array[key] = tempObject
    }
    return array
  },
  hash: function (str) {
    let hash = 0
    if (str.length === 0) {
      return hash
    }
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i)
      hash = ((hash << 5) - hash) + char
      hash = hash & hash
    }
    return hash
  },
  fallbackCopyTextToClipboard: function (text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
  },
  copyTextToClipboard: function (text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function () {
      console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });
  },
  isMobile: function () {
    const windowWidth = window.innerWidth
    if (windowWidth < 743) {
      return true
    }
    return false
  },
  scrollToTop: function () {
    $(window).scrollTop(0);
  },
  isPortraitMobile: function () {
    const windowWidth = window.innerWidth
    if (windowWidth < 576) {
      return true
    }
    return false
  },
  cursorWait: function (show) {
    if (show || show === undefined) {
      if (!document.querySelector('#wait-overlay')) {
        const overlay = document.createElement('DIV')
        overlay.id = 'wait-overlay'
        document.body.appendChild(overlay)
      }
    } else if (document.querySelector('#wait-overlay')) {
      const overlay = document.querySelector('#wait-overlay')
      document.body.removeChild(overlay)
    }
  },
  ucwords: function (str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  },
  ucfirst: function (str) {
    if (typeof str !== 'string') {
      return ''
    }
    return str.charAt(0).toUpperCase() + str.slice(1)
  },
  slugify: function (str) {
    const pathes = str.split('?')
    let params = ''
    if (pathes.length > 1) {
      str = pathes[0]
      params = '?' + pathes[1]
    }
    str = str.replace(/[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?]/g, "-");
    str = str
      .toLowerCase()
      .trim()
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '')
    return str + params
  },
  getIdFromSlug: function (slug) {
    const numb = slug.match(/\d+$/g);
    if (numb === null) {
      return 0
    }
    return numb[0] !== undefined ? parseInt(numb[0]) : 0
  },
  extractTagSearch: function (keyword) {
    if (keyword === undefined || keyword === '') {
      return false
    }
    const reg = new RegExp(/tag:"(.*?)"/g)
    const matches = keyword.match(reg)
    if (matches) {
      const tags = []
      let originKeyword = keyword
      matches.forEach(function (item) {
        originKeyword = originKeyword.replace(item, '')
        let tag = item.replace(/^tag:"/i, '')
        tag = tag.replace(/"$/i, '')
        tags.push(encodeURIComponent(tag))
      })
      if (tags.length) {
        return {
          tag: tags.join(','),
          keyword: originKeyword.trim()
        }
      }
    }
    return false
  },
  formatMoney: function (number, k_format = false) {
    if (k_format) {
      if (number > 1000) {
        const temp = parseInt(Math.round(number / 1000) * 1000)
        return temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return '1,000'
      }
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  simplifyNum: function (number) {
    if (!number && number != 0) return number;
    var str_num
    if (number >= 1E3 && number < 1E4) {
      str_num = number / 1E3
      return str_num + '千'
    } else if (number >= 1E4) {
      str_num = number / 1E4
      return str_num + '萬'
    } else { //一千以下
      return number
    }
  },
  shortenMoney: function (number, locale) {
    if (locale === 'zh') {
      return this.simplifyNum(number)
    }
    if (number < 1e3) return number;
    if (number >= 1e6) return +(number / 1e6).toFixed(1) + "M";
    if (number >= 1e3) return +(number / 1e3).toFixed(1) + "K";
  },
  formatCurrency: function (number) {
    number = parseFloat(number).toFixed(2);
    return '$' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  shortenDate: function (date) {
    const dateObj = new Date(date);
    const monthes = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    return dateObj.getDate() + ' ' + monthes[dateObj.getMonth()]
  },
  strToNumber: function (str) {
    return str.replace(/\D/g, '');
  },
  slugToWords: function (slug) {
    slug = decodeURIComponent(slug)
    slug = this.solrDecode(slug)
    return slug.replace(/-/g, ' ')
  },
  showResponseError: function () {
    const ComponentClass = Vue.extend(Modal)
    const instance = new ComponentClass({
      propsData: {
        id: 'response-error',
        title: 'Unable to connect',
        showClose: false,
        content: 'The web server is temporary overloaded and can\'t process your request. Please accept our apologies for the inconveniences this might cause to you.<br>Please try to refresh the page.',
        button: [{
          label: 'Refresh this page',
          callback: this.reload
        }]
      }
    })
    instance.$mount() // pass nothing
    document.querySelector('body').appendChild(instance.$el)
  },
  reload: function () {
    window.location.reload()
  },
  isSafari: function () {
    // const ua = navigator.userAgent.toLowerCase();
    // if (ua.indexOf('safari') != -1) { 
    //   if (ua.indexOf('chrome') > -1) {
    //     return false
    //   } else {
    //     return true
    //   }
    // }
    // return false
    if (navigator.userAgent.indexOf("Chrome") != -1) {
      return false
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      true
    }
    return false
  },
  getRecentKeyword: function (url) {
    let name = 'keyword'
    if (url !== undefined) {
      name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]')
      const regexS = '[\\?&@]' + name + '=([^&#]*)'
      const regex = new RegExp(regexS)
      const results = regex.exec(url)
      const keyword = results == null ? null : results[1].replace(/\+/g, ' ')
      if (keyword !== null && keyword.trim() == '') {
        return null
      }
      return decodeURIComponent(keyword)
    }
  },
  isSeoAlias: function (path, query) {
    const searched = this.handleAlias(path, query)
    if (searched.industry !== undefined || searched.cluster !== undefined || searched.sopt === 5 || searched.field !== undefined || searched.country !== undefined || searched.education !== undefined || searched.joblevel !== undefined || searched.employmenttype !== undefined) {
      return true
    }
    return false
  },
  checkLastItem: function (obj, fieldname, ary) {
    const last = obj[obj.length - 1]
    const resAry = []
    for (let i = 0; i < fieldname.length; i++) {
      const item = fieldname[i]
      if (last[item] !== '' || last[item]) {
        resAry.push(true)
      } else {
        resAry.push(false)
      }
    }
    if (ary) {
      return resAry
    }
    if (!ary) {
      const result = resAry.includes(false)
      if (result) {
        return false
      } else {
        return true
      }
    }
  },
  handleAlias: function (path, query) {
    let reg = new RegExp('^/industry/([^&]*)-jobs/')
    let matches = path.match(reg)
    query.search = ''
    if (matches && matches.length > 1) {
      query.industry = matches[1]
    }
    reg = new RegExp('^/cluster/([^&]*)(|/)')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.cluster = matches[1]
    }
    reg = new RegExp('^/jobs/([^&]*)/')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.field = matches[1]
    }
    reg = new RegExp('^/location/([^&]*)-jobs/')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.country = matches[1]
    }
    reg = new RegExp('^/job-level/([^&]*)-level-jobs/')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.joblevel = matches[1]
    }
    reg = new RegExp('^/work-type/([^&]*)-jobs/')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.employmenttype = matches[1]
    }
    reg = new RegExp('^/search-([^&]*)-jobs/')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.keyword = matches[1]
      query.search = matches[1].replace(/-/g, ' ')
    }
    reg = new RegExp('^/company-([^&]*)-jobs/')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.keyword = matches[1]
      query.search = matches[1].replace(/-/g, ' ')
      query.sopt = 5
    }
    reg = new RegExp('^/campaign/(.*?)?$')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.miscellaneous = matches[1].toUpperCase()
      query.campaign = matches[1]
    }
    return query
  },
  isEmpty: function (val) {
    return (!val.trim() || val.trim().length === 0)
  },
  getParameter: function (name, url) {
    if (url !== undefined) {
      name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]')
      const regexS = '[\\?&]' + name + '=([^&#]*)'
      const regex = new RegExp(regexS)
      const results = regex.exec(url)
      return results == null ? null : results[1]
    }
  },
  getQueryFromUrl: function (url) {
    let query = url.split('?')
    if (query.length > 1) {
      query = query[query.length - 1]
    } else {
      query = url
    }
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=')
          params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
          return params;
        }, {}
        )
      : {}
  },
  nl2br: function (html) {
    if (!html) {
      return ''
    }
    return html.replace(/\n/g, "<br/>")
  },
  br2nl: function (str) {
    const regex = /<br\s*[\/]?>/gi
    if (str === null) {
      return ''
    }
    if (str !== '') {
      return str.replace(regex, '\n')
    } else {
      return ''
    }
  },
  isProduction: function () {
    return process.env.cp_host.indexOf('www') === -1 ? false : true
  },
  languageHandler(item) {
    // return
    if (item) {
      if (typeof (item) === 'object') {
        return Object.prototype.hasOwnProperty.call(item, 'default') ? item['default'] : item['en']
      }
    }
    return item
  },
  isValidEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  isValidPhone(phone) {
    var re = /^[2-9][0-9]{7}$/;
    return re.test(String(phone).toLowerCase());
  },
  parseSalary(min, max, type) {
    let result
    min = this.formatSalary(min)
    max = this.formatSalary(max)
    if (min < 0 || max < 0) {
      min = null
      max = null
    }
    if (min && !max) {
      result = `Miniumn $${min}`
    } else if (!min && max) {
      result = `Maxiumn $${min}`
    } else if (min && max) {
      result = `$${min} - $${max}`
    } else {
      return 0
    }
    return type ? `${result} / ${type}` : result
  },
  formatSalary(number) {
    return number ? String(number).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : number
  },
  getCompanyLogo(logo) {
    return logo.indexOf('http') !== 0 ? process.env.BASE_API_URL + 'upload-files/' + logo : logo;
  },
  toExpiryDate(timestamp) {
    const currentTime = Math.floor(Date.now() / 1000)
    const difference = timestamp - currentTime
    const daysDifference = Math.floor(difference / 60 / 60 / 24)
    return 'Expires in ' + daysDifference + ' days'
  },
  toExiprationDate(timestamp) {
    if (isNaN(timestamp)) {
      return ''
    }
    timestamp = Number(String(timestamp).slice(0, 10))
    const now = Math.ceil(new Date().getTime() / 1000)
    const gap = now - timestamp
    // console.log('cpLang toExipre:', timestamp, now, gap)

    if (gap < 60 * 60) {
      const time = Math.floor(gap / 60)
      const unit =
        time > 0
          ? this.cpLang('global.mins_ago')
          : this.cpLang('global.min_ago')
      return time + ' ' + unit
    }
    if (gap < 60 * 60 * 24) {
      const time = Math.floor(gap / (60 * 60))
      const unit =
        time > 1
          ? this.cpLang('global.hours_ago')
          : this.cpLang('global.hour_ago')
      return time + ' ' + unit
    }
    if (gap < 60 * 60 * 24 * 7) {
      const time = Math.floor(gap / (60 * 60 * 24))
      const unit =
        time > 1
          ? this.cpLang('global.days_ago')
          : this.cpLang('global.day_ago')
      return time + ' ' + unit
    }
    if (gap < 60 * 60 * 24 * 9) {
      const time = 1
      const unit = this.cpLang('global.week_ago')
      return time + ' ' + unit
    }
    const date = new Date(timestamp * 1000)
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const month = 'recruitmentday.' + monthNames[date.getUTCMonth()].toLowerCase()
    return date.getDate() + ' ' + this.cpLang(month)
  },
  parseExp(min, max, minKey, maxKey) {
    min = minKey ? this.languageHandler(min) : ''
    max = maxKey ? this.languageHandler(max) : ''
    if (minKey === maxKey) {
      return min
    }
    if (!minKey && maxKey) {
      return maxKey > 1 && maxKey < 16 ? `${max} or below` : max
    }
    if (minKey && (!maxKey || maxKey === '0')) {
      return minKey > 1 ? `Minimum ${min}` : min
    }
    if (minKey >= 0 && maxKey <= 16) {
      return `${min} - ${max}`
    }
  },
  getSlug(input) {
    let val
    val = String(input).trim().toLowerCase()
    val = val.replace(/[\!@#\|\-.$%^&\/\*\)]/g, ' ')
    val = val.replace(/\s\s+/g, ' ');
    const res = val.replace(/\s+/g, '-').toLowerCase()
    return res
  },
  convertArrayToURLParam(data) {
    var out = []
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        out.push(key + '=' + encodeURIComponent(data[key]))
      }
    }
    return out.join('&')
  },
  formatDate(dateStr) {
    const date = new Date(dateStr)
    const month = date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    const day = date.getDate() > 10 ? date.getDate() : "0" + date.getDate()
    return date.getFullYear() + '/' + month + '/' + day
  },
  calcDay(timestamp) {
    const now = Math.ceil(new Date().getTime() / 1000)
    if (isNaN(timestamp)) {
      const date = new Date(timestamp)
      timestamp = Math.ceil(date.getTime() / 1000)
    } else {
      const gap = now >= timestamp ? now - timestamp : timestamp - now
      const time = Math.floor(gap / (60 * 60 * 24))
      return time
    }
  },
  getBearTokenHeader(tokenKey) {
    let header = {};
    const token = this.getLocalStorage(tokenKey);
    if(token) {
      header.Authorization = token.token;
    }
    return header;
  },
  setLocalStorage(key, value, duration, encrypt = false) {
    if (duration === undefined) {
      duration = 300
    }
    duration = duration * 1000
    const now = new Date()
    const item = {
      value: encrypt ? window.btoa(unescape(encodeURIComponent(JSON.stringify(value)))) : JSON.stringify(value),
      expiry: now.getTime() + duration
    }
    Cookies.set(key, JSON.stringify(item), { expires: 7 });
  },
  getLocalStorage(key, decrypt = false) {
    // if (!process.client) {
    //   return false
    // }
    const now = new Date()
    const item = Cookies.get(key);
    if (!item || item === undefined) {
      return false
    }
    const data = JSON.parse(item)
    if (data.expiry < now.getTime()) {
      // expired, clear it
      Cookies.get(key);
      return false
    }
    try {
      return JSON.parse(decrypt ? decodeURIComponent(escape(window.atob(data.value))) : data.value)
    } catch (e) {
      return false
    }
  },
  isAuth() {
    return this.getLocalStorage('access_token') ? true : false;
  },
  getUsername() {
    return this.getLocalStorage('username');
  },
  authorize(token, axios, redirectUrl = undefined) {
    this.setLocalStorage('access_token', token, 1800);
    axios.get('user/me').then((res) => {
      const profile = res.data.data
      const username = profile.firstName ? profile.firstName + ' ' + profile.lastName : profile.email;
      // redirection
      if (redirectUrl === undefined) {
        const checkFields = ['firstName', 'lastName']
        let completed = true
        checkFields.forEach((field) => {
          if (profile[field] === undefined || profile[field] === '') {
            completed = false;
          }
        })
        window.location.href = completed ? '/profile/reward' : '/profile/';
      }
      this.setLocalStorage('username', username, 3600);
    })
    if (redirectUrl !== undefined && redirectUrl !== '') {
      window.location.href = redirectUrl;
    }
  },
  removeLocalStorage(key) {
    Cookies.remove(key);
  },
  toast(msg, priority = 'primary') {
    if (document.getElementById('toast') !== null) {
      document.getElementById('toast').remove()
    }
    clearTimeout(this.toastTimeout)
    // let html = '<div class="toast-header bg-' + priority + '">'
    // html +='<strong class="mr-auto">' + subject + '</strong>'
    // html +='<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">'
    // html +='<span aria-hidden="true" id="toast-close">&times;</span></button></div>'
    const html = '<div class="toast-body bg-' + priority + '"><i class="fas fa-info-circle"></i>' + msg + '</div>'
    const toast = document.createElement('DIV')
    toast.classList.add('toast')
    toast.id = 'toast'
    toast.innerHTML = html
    document.body.appendChild(toast)
    // document.getElementById('toast-close').onclick = this.hideToast
    const vm = this
    setTimeout(function () {
      toast.classList.add('show')
      vm.toastTimeout = setTimeout(vm.hideToast, 5000)
    }, 0)
  },
  hideToast() {
    const toast = document.getElementById('toast')
    if (toast !== null) {
      toast.classList.remove('show')
      this.toastTimeout = setTimeout(function () {
        toast.remove()
      }, 300)
    }
  },
  hideMessage() {
    if (document.getElementById('prompt-message') !== null) {
      document.getElementById('prompt-message').remove()
    }
  },
  message(title, content, showClose = true, type = 'text', callback = function () { }) {
    if (document.getElementById('prompt-message') !== null) {
      document.getElementById('prompt-message').remove()
    }
    let html = '<div role="document" class="modal-dialog"><div class="modal-content">'
    if (showClose) {
      html += '<button type="button" data-dismiss="modal" aria-label="Close" class="prompt-close close"><span aria-hidden="true">×</span></button>'
    }
    if (title !== false && title !== '') {
      html += '<div class="modal-header">' + title + '</div>'
    }
    if (type === 'loading') {
      content = '<div class="progress"><div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div></div>'
    }
    html += '<div class="modal-body">' + content + '</div>'
    if (showClose) {
      html += '<div class="modal-footer"><button type="button" class="btn btn-defaul prompt-close">' + this.cpLang('jd.close') + '</button></div>'
    }
    const message = document.createElement('DIV')
    message.classList.add('modal')
    message.id = 'prompt-message'
    message.innerHTML = html
    document.body.appendChild(message)
    if (showClose) {
      const vm = this
      document.querySelectorAll('.prompt-close').forEach((item) => {
        item.onclick = function (e) {
          callback()
          vm.hideMessage()
        }
      })
    }
  },
  eleFocus: function (e) {
    document.querySelector(e).focus()
  },
  checkVal: function (v, int) {
    if (v === '0' || v === null || v === undefined) {
      return ''
    }
    if (int) {
      return parseInt(v)
    } else {
      return v
    }
  },
  getValue: function (e) {
    const fieldName = $(e).attr(RESULT_FIELD_NAME);
    const isCheckbox = $(e).is(':checkbox');
    const isRadio = $(e).is(':radio');
    const vm = this
    if (isCheckbox) {
      const checkboxes = $('[' + RESULT_FIELD_NAME + '=' + fieldName + ']:checked');
      if (vm.isSingleField(fieldName)) {
        if (checkboxes.length === 0) return '';
        return vm.getFieldValue(checkboxes.get(0));
      } else if (vm.isMultipleField(fieldName)) {
        const values = [];
        checkboxes.each(function (i, e) {
          values.push(vm.getFieldValue(e));
        });
        return values.join(",");
      } else if (!$(e).is(':checked')) {
        return "";
      }
    }
    // if (isRadio) {
    //   if (!$(e).is(":checked")) return ""; // don't retrieve the value from radio which is not selected
    // }
    return this.getFieldValue(e);
  },
  getFieldValue: function (e) {
    if ($(e).is('[' + RESULT_FIELD_TYPE + ']') && $(e).attr(RESULT_FIELD_TYPE) === 'mobile') {
      return this.formatMobile($(e).val());
    }
    if ($(e).is('[' + RESULT_FIELD_VALUE + ']')) {
      return $(e).attr(RESULT_FIELD_VALUE);
    }
    if ($(e).is('[' + RESULT_FIELD_TYPE + ']') && $(e).attr(RESULT_FIELD_TYPE) === 'hkid') {
      return this.formatHKID($(e).val());
    }
    return $(e).val();
  },
  isSingleField: function (fieldName) {
    if ($('[' + RESULT_FIELD_NAME + '=' + fieldName + ']').length <= 1) return false;
    let result = false;
    $('[' + RESULT_FIELD_NAME + '=' + fieldName + ']').each(function (i, e) {
      if ($(e).is('[field-single]')) result = true;
    })
    return result;
  },
  isMultipleField: function (fieldName) {
    if ($('[' + RESULT_FIELD_NAME + '=' + fieldName + ']').length <= 1) return false;
    let result = false;
    $('[' + RESULT_FIELD_NAME + '=' + fieldName + ']').each(function (i, e) {
      if ($(e).is('[field-multiple]')) result = true;
    })
    return result;
  },
  getFieldTitle: function (fieldName) {
    const titleEle = $('[' + RESULT_FIELD_NAME + '=' + fieldName + '][' + RESULT_FIELD_TITLE + ']');
    if (titleEle.length === 0) {
      console.error('Field: [' + fieldName + '] did not has title for it.');
      return "";
    }
    return titleEle.attr(RESULT_FIELD_TITLE);
  },
  getFieldOrder: function (e) {
    if ($(e).is('[' + RESULT_FIELD_ORDER + ']')) return $(e).attr(RESULT_FIELD_ORDER);
    return '';
  },
  parseFormData: function (form_id) {
    const result = [];
    const vm = this
    $("#" + form_id).find('[' + RESULT_FIELD_NAME + ']:not(:file)').each(function (i, e) {
      const fieldName = $(e).attr(RESULT_FIELD_NAME);
      const alreadySet = result.filter(r => r['fieldName'] === fieldName).length > 0;
      const previousValue = alreadySet ? result.filter(r => r['fieldName'] === fieldName).map(r => r.fieldValue) : undefined;
      if (previousValue === '' || previousValue === undefined) { // handle using multi input checkbox for one answer case
        if (alreadySet) {
          result.forEach((r) => {
            if (r['fieldName'] === fieldName) {
              r.fieldValue = getValue(e);
            }
          })
        } else {
          result.push({
            fieldName: fieldName,
            fieldTitle: vm.getFieldTitle(fieldName),
            fieldValue: vm.getValue(e),
            fieldType: $(e).is('[' + RESULT_FIELD_TYPE + ']') ? $(e).attr(RESULT_FIELD_TYPE) : 'text',
            fieldOrder: vm.getFieldOrder(e),
            fieldAttrs: $(e).is('[' + RESULT_FIELD_ATTRS + ']') ? $(e).attr(RESULT_FIELD_ATTRS).split(',') : [],
          });
        }
      }
    });
    return result
  },
  prepareFormData: function (form_id, result) {
    const formData = new FormData();
    // handle file collecting
    const vm = this
    $("#" + form_id).find('[' + RESULT_FIELD_NAME + ']:file').each(function (i, e) {
      const fieldName = $(e).attr(RESULT_FIELD_NAME);
      const fieldAttrs = $(e).attr(RESULT_FIELD_ATTRS);
      if (result.filter(r => r[RESULT_FIELD_NAME] === fieldName).length === 0) {
        const field = {
          fieldName: fieldName,
          fieldTitle: vm.getFieldTitle(fieldName),
          fieldType: 'file',
          fieldOrder: vm.getFieldOrder(e)
        }
        if (fieldAttrs) {
          field['fieldAttrs'] = fieldAttrs.split(',')
        }
        result.push(field);
      }
      if ($(e).get(0).files.length > 0) formData.append(fieldName + "_" + result.filter(r => r[RESULT_FIELD_NAME] === fieldName).length, $(e).get(0).files[0])
    });
    // append lang field
    const lang = {
      fieldName: "lang",
      fieldTitle: "Lang",
      fieldType: 'lang',
      fieldValue: this.getLocale()
    }
    result.push(lang);
    formData.append("result", JSON.stringify(result));
    if ($("#" + form_id).find('.verify-input').length > 0) {
      const verifyCode = $("#" + form_id).find('.verify-input input').val();
      const fieldName = $("#" + form_id).find('.verify-input input').attr('name');
      formData.append(fieldName, verifyCode);
    }
    return formData;
  },
  getLocale() {
    let locale = 'zh'
    const acceptLangs = ['zh', 'en']
    if (acceptLangs.includes(this.getParameter("lang"))) {
      locale = this.getParameter("lang")
      return locale
    }
    return this.getCookie('locale') || "zh"
  },
  getQuery: function () {
    const url = document.location.href;
    if (url.indexOf('?') < 0) return {};
    const qs = url.substring(url.indexOf('?') + 1).split('&');
    for (let i = 0, result = {}; i < qs.length; i++) {
      qs[i] = qs[i].split('=');
      const val = decodeURIComponent(qs[i][1]);
      if (val !== 'undefined' && val !== undefined && $.trim(val) !== '') result[qs[i][0]] = val;
    }
    // eslint-disable-next-line no-undef
    return result;
  },
  formatHKID: function (value) {
    if (value.indexOf("-") == -1 && value.length === 8) {
      return value.splice(7, 0, "-");
    }
    return value
  },
  formatMobile: function (value) {
    if (value.indexOf("+852") == -1) {
      return '+852' + value;
    }
    return value
  },
  shortenMobile: function (value) {
    if (value.indexOf("+852") == 0) {
      return value.replace("+852", "")
    }
    if (value.indexOf("852") == 0) {
      return value.replace("852", "")
    }
    return value
  },
  getCookie: function (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },
  initForm: function () {
    const t = window.vm.t
    $(".form-content").on('change', 'input[type="file"]', function (e) {
      const wrapper = $(this).parents('.files')
      const parent = $(this).parent();
      const info = wrapper.find('.selected-files')
      const isMultiple = wrapper.hasClass("multiple")
      if (isMultiple) {
        if ($.trim($(this).val()) !== '') {
          $(this).next("button").removeClass('has-error').text(t("form.add_file"))
          const newFile = $(this).clone()
          newFile.val("")
          newFile.attr("required", false)
          parent.append(newFile)
        } else {
          $(this).next("button").text(t("form.upload_file"))
        }
        info.empty();
        wrapper.find('input[type="file"]').each(function (i, item) {
          if (item.files.length > 0) {
            const file = item.files[0]
            info.append('<span>' + file.name + '<i class="fas fa-times"/></span>');
            $(this).parents('.col-xs-12').removeClass('has-error')
          }
        })
        if (info.find('span').length > 0) {
          wrapper.find('.btn').removeClass('has-error').text(t("form.add_file"))
        }
      } else {
        info.empty();
        const file = e.target.files[0]
        info.append('<span>' + file.name + '<i class="fas fa-times"/></span>');
        $(this).parents('.col-xs-12').removeClass('has-error')
        if ($.trim($(this).val()) !== '') {
          $(this).next("button").removeClass('has-error').text(t("form.change_file"))
        } else {
          $(this).next("button").text(t("form.upload_file"))
        }
      }
    })
    $(".form-content").on('click', '.selected-files i', function (e) {
      const wrapper = $(this).parents('.files')
      const isMultiple = wrapper.hasClass("multiple")
      if (isMultiple) {
        const info = wrapper.find('.selected-files')
        const span = $(this).parent();
        const index = info.find('span').index(span)
        wrapper.find("input[type=file]").eq(index).remove();
        span.remove();
        if (info.find('span').length === 0) {
          wrapper.find('.btn').addClass("has-error").text(t("form.upload_file"))
        }
      } else {
        const file = wrapper.find('input[type="file"]')
        file.after(file.clone().val(""));
        file.remove();
        $(this).parent().remove();
      }
    })
  },
  formValidate: function (id = "application-form") {
    // validate
    const form = $("#" + id)
    let errorField = false
    const vm = this
    const translator = window.vm.t
    form.find('input:visible,.v-select:visible').each(function (i) {
      const col = $(this).parents('.col-xs-12')
      if ($(this).prop('required')) {
        if ($(this).prop('readonly')) {
          return;
        }
        if ($(this).val() === '' || ($(this).attr('type') === 'checkbox' && !$(this).prop('checked'))) {
          col.addClass('has-error')
          if (!errorField) {
            errorField = $(this);
          }
        } else if ($(this).attr('type') == 'email' && !vm.isValidEmail($(this).val())) {
          col.addClass('has-error')
          vm.toast(translator('common.invalid_email'), 'warning')
          if (!errorField) {
            errorField = $(this);
          }
        } else if ($(this).hasClass('hkid-input') && !vm.isHKID($(this).val())) {
          col.addClass('has-error')
          vm.toast(translator('common.invalid_hkid'), 'warning')
          if (!errorField) {
            errorField = $(this);
          }
        } else if ($(this).attr('type') === 'radio') {
          const outsideLabel = col.find('label.required').length === 0
          const container = outsideLabel ? col.parents('.form-group') : col
          const fieldName = $(this).attr('name')
          const val = $("input[name='" + fieldName + "']:checked").val()
          if (val === undefined) {
            if (!errorField) {
              errorField = $(this);
            }
            container.addClass('has-error')
          } else {
            container.removeClass('has-error')
          }
        } else if ($(this).attr('name') === 'mobile' && col.find('.verify-input').length) {
          const input = col.find('.verify-input input')
          if (input.val() === '') {
            col.addClass('has-error')
            vm.toast(translator('register.enter_code'), 'warning')
            if (!errorField) {
              errorField = input;
            }
          }
        } else {
          col.removeClass('has-error')
        }
      }
      if ($(this).attr('required') && $(this).hasClass('v-select')) {
        if ($(this).find('.vs__selected').text() === '') {
          col.addClass('has-error')
          if (!errorField) {
            errorField = $(this);
          }
        } else {
          col.removeClass('has-error')
        }
      }
    })
    if (errorField) {
      if (errorField.hasClass('v-select')) {
        errorField.find('.vs__dropdown-toggle input').focus();
      } else {
        errorField.focus();
      }
      return false;
    }
    // combine contact time
    const contactTime = form.find('input[name="contact_time"]')
    if (contactTime.length > 0) {
      const contactDate = form.find('input[name="custom_date"]').val()
      const contactFrom = $.trim(form.find('[name="contact_from"]').find('.vs__selected').text())
      const contactTo = $.trim(form.find('[name="contact_to"]').find('.vs__selected').text())
      contactTime.val(contactDate + " " + contactFrom + "-" + contactTo)
    }
    return true;
  },
  surveyFormSubmit: function (form_id, submit_id, success, failure, recaptcha, extra_handle = false, token = "") {
    recaptcha.execute('varifyGRecaptcha').then((recaptchaToken) => {
      let formData = new FormData();
      const data = {
        "lang": this.getLocale()
      }
      let hasFile = false;
      $('#' + form_id).find("input").each(function (i, e) {
        const fieldName = $(this).attr("fieldname") || $(this).attr("fieldName")
        const fieldType = $(this).attr("fieldtype") || $(this).attr("fieldType")
        const type = $(this).attr("type")
        if (fieldName === undefined) {
          return;
        }
        if (fieldName === undefined || type === "file") {
          const fieldTitle = $(this).attr("fieldTitle");
          const file = $(e).get(0).files[0]
          if (file !== undefined) {
            formData.append(fieldTitle, $(e).get(0).files[0])
          }
          hasFile = true;
          return;
        }

        if (type === "radio" || type === "hidden") {
          if (fieldType === "json") {
            try {
              data[fieldName] = JSON.parse($(this).val())
            } catch {
              delete data[fieldName]
            }
            return;
          }
          data[fieldName] = $(this).attr("fieldvalue") || $(this).val()
          return;
        }
        data[fieldName] = $(this).val();
      });

      $('#' + form_id).find(".v-select").each(function () {
        const fieldName = $(this).attr("fieldname")
        if (fieldName !== undefined) {
          data[fieldName] = $(this).attr("fieldvalue")
        }
      });
      //format form data
      for (var key in data) {
        if (key !== '') {
          const val = data[key];
          // format number field value
          if (['amount', 'income', 'monthlyRepayment', 'remainingLoanAmount', 'remainingLoans', 'tenor', 'propertyRepayment', 'propertyCount',
            'propertyMortgage', 'productId', 'creditCardOustanding', 'mortgageAppliedCount',
            'visaValidityPeriod', 'mortgage', 'propertyValue', 'receivableAmount', 'currentMortgage', 'age', 'creditCardCount', 'exp',
            'companyId', 'vendorId', 'housingCost', 'mortgageCount', 'mortgageOutstanding', 'mortgageRepayment', 'previousIvaYear',
            'ploanCount', 'ploanOutstanding', 'ploanRepayment', 'ploanAppliedCount', 'ploanAppliedCount', 'previousBadDebtCount',
            'mortgagePayment', 'mortgageTenor', 'otherLoanAmount', 'otherLoanPayment'].indexOf(key) !== -1) {
            data[key] = parseFloat(val) || 0;
          }
          // format boolean field value
          if (['bornInHK', 'hasLoan', 'hasProperty', 'permanentHkid', 'audit', 'hasDebt', 'bornInHk', 'pos', 'hkPermanentResident', 'hasPreviousIva',
            'applied', 'hasPreviousIva', 'hasPreviousIva',
            'hasPreviousBadDebt'].indexOf(key) !== -1) {
            if (val === '') {
              delete data[key]
            } else {
              data[key] = val === 'yes' || val === '有'
            }
          }
          //format array field value
          if (['incomeProofType'].indexOf(key) !== -1) {
            data[key] = $.trim(val) === '' ? [] : val.split(',')
          }
        }
        // // format json field value
        // if (['loanHistory'].indexOf(key) !== -1) {
        //   data[key] = val === "" ? [] : JSON.parse(val)
        // }
      }
      if (hasFile) {
        // console.log("has file-----------------------------------")
        formData.append("form", JSON.stringify(data));
      } else {
        formData = data;
      }
      // submit data
      const url = process.env.BASE_API_URL + submit_id + '/submit';
      let headers = {};
      if(token !== "") headers['token'] = token;
      headers['recaptcha-response'] = recaptchaToken;
      axios.post(url, formData, {headers: headers})
          .then(function (response) {
            if (response.data.success) {
              if (success) success(response);
            } else if (failure) failure(response.data.message || response.data.code);
          }).catch(function (error) {
        if (failure) failure(error);
      });
    });
  },
  formSubmit: function (form_id, submit_id, success, failure, extra_handle = false) {
    let result = this.parseFormData(form_id);
    if (extra_handle) {
      result = extra_handle(result)
    }
    const formData = this.prepareFormData(form_id, result);
    // submit data
    const url = process.env.BASE_API_URL + 'survey/submit/' + submit_id
    // console.log(result)
    axios.post(url, formData, {})
      .then(function (response) {
        if (response.data.success) {
          if (success) success(response);
        } else if (failure) failure(response.data.message || response.data.code);
      }).catch(function (error) {
        if (failure) failure(error);
      });
  },
  getAge: function (dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    console.warn(dateString)
    console.warn(birthDate)
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  },
  backHandle: function () {
    if (window !== top) {
      window.location.href = "/";
    } else {
      window.open("", "_self");
      window.close();
    }
  },
  getCurrentTimestamp: function () {
    const now = new Date();
    const year = now.getFullYear();
    const month = ('0' + (now.getMonth() + 1)).slice(-2); // 月份是从0开始的
    const day = ('0' + now.getDate()).slice(-2);
    const hour = ('0' + now.getHours()).slice(-2);
    const minute = ('0' + now.getMinutes()).slice(-2);
    const second = ('0' + now.getSeconds()).slice(-2);
    const formattedTimestamp = `${year}${month}${day}${hour}${minute}${second}`;
    return formattedTimestamp;
  },
  convertIdentity(realIdentity) {
    return realIdentity + "-" + this.getCurrentTimestamp();
  },
  isMagicIdentity(realIdentity) {
    return "P8888888" === realIdentity;
  }
}
export default commonFunc
